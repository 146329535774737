import { DialogHandler } from "../../../components/dialog/useDialogHandler";
import {
  FamilyBonusCard,
  FamilyBonusCardOffer,
  FamilyBonusCardOfferRedemptionMethod,
  getRedemptionMethodsForOffer,
  OfferRedeemabilityCheckCardResponse,
  OfferRedeemabilityCheckResponse,
  RedeemabilityCheckStatus,
} from "../../../model/family.bonus.card";
import HandlerDialog from "../../../components/dialog/handlerDialog";
import { isMobile } from "react-device-detect";
import intl from "react-intl-universal";
import {
  Button,
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";
import useDialogReset from "../../../components/dialog/useDialogReset";
import * as FamilyBonusCardActions from "../../../actions/family.bonus.cards";
import { useDispatch } from "react-redux";
import ManageFbcLoadingCard from "./ManageFbcLoadingCard";
import ManageFbcErrorCard from "./ManageFbcErrorCard";
import { useLocale } from "../../../hooks/useLocale";
import FbcCardLayout from "./FbcCardLayout";
import { format, parseISO } from "date-fns";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FbcCardBuyButton from "./FbcCardBuyButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      margin: 0,
      paddingTop: 0,
    },
    actionContainer: {
      marginTop: 16,
      display: "flex",
      justifyContent: "center",
    },
    actionButton: {
      minWidth: 168,
      [theme.breakpoints.down("xs")]: {
        minWidth: 165,
        flex: 1,
      },
    },
    cardCell: {
      borderBottom: 0,
    },
    section: {
      paddingTop: "0px",
      paddingBottom: "0px",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    divider: {
      marginTop: "10px",
    },
    cardCellCenter: {
      textAlign: "center",
      borderBottom: 0,
    },
    cardCellCenterError: {
      textAlign: "center",
      borderBottom: 0,
      color: theme.palette.error.main,
    },
  }),
);

enum RedemptionStep {
  methodSelection,
  cardSelection,
  redemption,
}

export default function DialogFbcOfferRedemption({
  handler,
}: {
  handler: DialogHandler<FamilyBonusCardOffer>;
}) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [currentStep, setCurrentStep] = useState<RedemptionStep>(
    RedemptionStep.methodSelection,
  );

  const [chosenRedemptionMethod, setChosenRedemptionMethod] =
    useState<FamilyBonusCardOfferRedemptionMethod>();
  const [chosenCard, setChosenCard] = useState<FamilyBonusCard>();

  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [redeemabilityCheckResult, setRedeemabilityCheckResult] =
    useState<OfferRedeemabilityCheckResponse>();
  const dispatch = useDispatch();
  const locale = useLocale();

  useDialogReset(handler, () => {
    setRedeemabilityCheckResult(undefined);
    setFailed(false);
    setLoading(false);
    setCurrentStep(RedemptionStep.methodSelection);
    setChosenRedemptionMethod(undefined);
    setChosenCard(undefined);
  });

  async function checkRedemptionMethods() {
    if (
      !handler.payload ||
      !handler.isOpen ||
      currentStep !== RedemptionStep.methodSelection
    ) {
      return;
    }

    const methods = getRedemptionMethodsForOffer({
      offer: handler.payload,
    });
    if (
      methods.length === 1 &&
      !chosenRedemptionMethod &&
      methods[0] !== FamilyBonusCardOfferRedemptionMethod.online
    ) {
      setChosenRedemptionMethod(methods[0]);
      setCurrentStep(RedemptionStep.cardSelection);
    }
  }

  async function loadCards() {
    if (
      !handler.payload ||
      !handler.isOpen ||
      currentStep !== RedemptionStep.cardSelection
    ) {
      return;
    }
    setLoading(true);
    setRedeemabilityCheckResult(undefined);
    setLoading(true);
    setFailed(false);
    dispatch(
      FamilyBonusCardActions.checkOfferRedeemability({
        offer_id: handler.payload.offer_id,
        completion: (checkResult) => {
          setRedeemabilityCheckResult(checkResult);
          setLoading(false);
          setFailed(false);
        },
        error: () => {
          setRedeemabilityCheckResult(undefined);
          setLoading(false);
          setFailed(true);
        },
      }),
    );
  }

  useEffect(() => {
    checkRedemptionMethods();
    loadCards();
  }, [handler.payload, handler.isOpen, currentStep]);

  if (!handler.payload) {
    return null;
  }

  const methods = getRedemptionMethodsForOffer({
    offer: handler.payload,
  });

  function renderOnlineRedemptionMethodSelection() {
    if (methods.includes(FamilyBonusCardOfferRedemptionMethod.online)) {
      return (
        <Grid item className={classes.actionContainer} xs={12}>
          <Button
            disabled={true}
            color={"primary"}
            className={classes.actionButton}
            onClick={() => {
              setChosenRedemptionMethod(
                FamilyBonusCardOfferRedemptionMethod.online,
              );
              setCurrentStep(RedemptionStep.cardSelection);
            }}>
            {intl.get(
              "fbc_offer_redemption.method_selection.redemption_online",
            )}
          </Button>
        </Grid>
      );
    }
  }

  function renderOfflineRedemptionMethodSelection() {
    if (methods.includes(FamilyBonusCardOfferRedemptionMethod.offline)) {
      return (
        <Grid item className={classes.actionContainer} xs={12}>
          <Button
            color={"primary"}
            className={classes.actionButton}
            onClick={() => {
              setChosenRedemptionMethod(
                FamilyBonusCardOfferRedemptionMethod.offline,
              );
              setCurrentStep(RedemptionStep.cardSelection);
            }}>
            {intl.get(
              "fbc_offer_redemption.method_selection.redemption_offline",
            )}
          </Button>
        </Grid>
      );
    }
  }

  function renderSelfRedemptionMethodSelection() {
    if (true) {
      // TODO: Not available at the moment
      return null;
    }
    if (methods.includes(FamilyBonusCardOfferRedemptionMethod.selfRedemption)) {
      return (
        <Grid item className={classes.actionContainer} xs={12}>
          <Button
            color={"primary"}
            className={classes.actionButton}
            onClick={() => {
              setChosenRedemptionMethod(
                FamilyBonusCardOfferRedemptionMethod.selfRedemption,
              );
              setCurrentStep(RedemptionStep.cardSelection);
            }}>
            {intl.get(
              "fbc_offer_redemption.method_selection.redemption_self_redemption",
            )}
          </Button>
        </Grid>
      );
    }
  }

  function renderOfferRedeemabilityCheckError() {
    if (
      redeemabilityCheckResult &&
      redeemabilityCheckResult.offer === RedeemabilityCheckStatus.redeemable
    ) {
      return null;
    }

    if (redeemabilityCheckResult !== undefined) {
      return (
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.cardCellCenterError}>
                {intl.get(
                  `fbc_offer_redemption.card_selection.error_${redeemabilityCheckResult.offer.toString()}`,
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    }
    return null;
  }

  function renderOfflineRedemption() {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid container className={classes.section} spacing={0}>
            <Grid item xs={12}>
              <Typography
                style={{ textAlign: "center", marginBottom: "40px" }}
                variant={"body1"}
                dangerouslySetInnerHTML={{
                  __html: intl.get(
                    `fbc_offer_redemption.redemption.offline_message`,
                  ),
                }}
              />
            </Grid>
          </Grid>
          <FbcCardLayout
            card={chosenCard}
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
          />
        </Grid>
      </Grid>
    );
  }

  function renderRedeemabilityIssueForCard({
    cardCheck,
  }: {
    cardCheck: OfferRedeemabilityCheckCardResponse;
  }) {
    if (
      cardCheck.status === RedeemabilityCheckStatus.expiredCard ||
      cardCheck.status === RedeemabilityCheckStatus.invalidCard ||
      cardCheck.status === RedeemabilityCheckStatus.maximumUsagesReached ||
      cardCheck.status === RedeemabilityCheckStatus.maximumUsagesPerDayReached
    ) {
      return (
        <Grid item xs={12}>
          <Typography variant={"body2"} color={"error"}>
            {intl.get(
              `fbc_offer_redemption.card_selection.error_${cardCheck.status.toString()}`,
            )}
          </Typography>
        </Grid>
      );
    }
    return null;
  }

  function hasRedeemabilityIssueForCard({
    cardCheck,
  }: {
    cardCheck: OfferRedeemabilityCheckCardResponse;
  }) {
    if (
      cardCheck.status === RedeemabilityCheckStatus.expiredCard ||
      cardCheck.status === RedeemabilityCheckStatus.invalidCard ||
      cardCheck.status === RedeemabilityCheckStatus.maximumUsagesReached ||
      cardCheck.status === RedeemabilityCheckStatus.maximumUsagesPerDayReached
    ) {
      return true;
    }
    return false;
  }

  return (
    <HandlerDialog
      onBackdropClick={() => {
        handler.close();
      }}
      handler={handler}
      maxWidth={"sm"}
      fullWidth={true}
      fullScreen={isMobile}>
      <DialogTitle>
        {currentStep === RedemptionStep.methodSelection &&
          intl.get("fbc_offer_redemption.method_selection.title")}
        {currentStep === RedemptionStep.cardSelection &&
          intl.get("fbc_offer_redemption.card_selection.title")}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {currentStep === RedemptionStep.methodSelection && (
          <Grid container xs={12}>
            {renderOnlineRedemptionMethodSelection()}
            {renderOfflineRedemptionMethodSelection()}
            {renderSelfRedemptionMethodSelection()}
          </Grid>
        )}

        {currentStep === RedemptionStep.cardSelection && (
          <>
            {loading && <ManageFbcLoadingCard />}
            {failed && <ManageFbcErrorCard />}
            {renderOfferRedeemabilityCheckError()}
            {!loading &&
              !failed &&
              redeemabilityCheckResult &&
              redeemabilityCheckResult.cards.length > 0 && (
                <Grid
                  container
                  spacing={2}
                  style={{ marginTop: "15px", marginBottom: "15px" }}>
                  {redeemabilityCheckResult.cards.map((value) => {
                    return (
                      <>
                        <Grid
                          item
                          container
                          xs={12}
                          direction={"row"}
                          justify={"space-between"}
                          wrap={"nowrap"}>
                          <Grid item container direction="row">
                            <Grid item xs={12}>
                              <Typography variant={"h5"}>
                                {intl.get(
                                  "fbc_offer_redemption.card_selection.value_card_id",
                                  { card_id: value.card.fbc_card_number },
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant={"body2"}>
                                {intl.get(
                                  "fbc_offer_redemption.card_selection.value_valid_until",
                                  {
                                    valid_until: format(
                                      parseISO(value.card.valid_until),
                                      "dd.MM.yyyy",
                                      {
                                        locale: locale.dateFns,
                                      },
                                    ),
                                  },
                                )}
                              </Typography>
                            </Grid>
                            {renderRedeemabilityIssueForCard({
                              cardCheck: value,
                            })}
                          </Grid>

                          <Grid item>
                            <Button
                              disabled={
                                hasRedeemabilityIssueForCard({
                                  cardCheck: value,
                                }) ||
                                redeemabilityCheckResult.offer !==
                                  RedeemabilityCheckStatus.redeemable
                              }
                              onClick={() => {
                                setChosenCard(value.card);
                                setCurrentStep(RedemptionStep.redemption);
                              }}
                              color="primary">
                              {intl.get(
                                "fbc_offer_redemption.card_selection.action_choose_card",
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      </>
                    );
                  })}
                  <FbcCardBuyButton veryReduced={true} />
                </Grid>
              )}
          </>
        )}

        {currentStep === RedemptionStep.redemption &&
          chosenRedemptionMethod ===
            FamilyBonusCardOfferRedemptionMethod.offline &&
          renderOfflineRedemption()}
      </DialogContent>
      <DialogActions>
        {currentStep === RedemptionStep.methodSelection && (
          <Button
            style={{ marginRight: "15px", marginBottom: "15px" }}
            color="secondary"
            onClick={() => {
              handler.close();
            }}>
            {intl.get("fbc_offer_redemption.method_selection.action_cancel")}
          </Button>
        )}
        {currentStep === RedemptionStep.cardSelection && (
          <>
            {methods.length > 1 && (
              <Button
                style={{ marginBottom: "15px" }}
                color="secondary"
                onClick={() => {
                  setCurrentStep(RedemptionStep.methodSelection);
                }}>
                {intl.get(
                  "fbc_offer_redemption.card_selection.action_change_method",
                )}
              </Button>
            )}
            <Button
              style={{ marginRight: "15px", marginBottom: "15px" }}
              color="secondary"
              onClick={() => {
                handler.close();
              }}>
              {intl.get("fbc_offer_redemption.card_selection.action_cancel")}
            </Button>
          </>
        )}
        {currentStep === RedemptionStep.redemption && (
          <>
            {chosenRedemptionMethod ===
              FamilyBonusCardOfferRedemptionMethod.offline && (
              <Grid
                item
                xs={12}
                container
                direction="column"
                alignItems={"center"}
                style={{ marginBottom: "15px" }}>
                <Button
                  color="primary"
                  onClick={() => {
                    handler.close();
                  }}>
                  {intl.get(
                    "fbc_offer_redemption.redemption.action_offline_redemption_finish",
                  )}
                </Button>
              </Grid>
            )}
          </>
        )}
      </DialogActions>
    </HandlerDialog>
  );
}
