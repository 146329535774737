import {
  Box,
  Button,
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import { DialogHandler } from "../../../components/dialog/useDialogHandler";
import { isMobile } from "react-device-detect";
import HandlerDialog from "../../../components/dialog/handlerDialog";
import { AuthenticationHelper } from "../../../utils/authenticationHelper";
import intl from "react-intl-universal";
import React from "react";
import FbcCardLayout from "./FbcCardLayout";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      paddingTop: "0px",
      paddingBottom: "0px",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    dialogContent: {
      margin: 0,
      paddingTop: 0,
    },
    innerContent: {
      marginTop: 30,
      paddingLeft: 20,
      paddingRight: 20,
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 15,
        paddingRight: 15,
      },
    },
    actionContainer: {
      [theme.breakpoints.down("xs")]: {
        display: "flex",
      },
    },
    registerButton: {
      minWidth: 168,
      marginRight: 16,
      [theme.breakpoints.down("xs")]: {
        minWidth: 165,
        marginRight: 15,
        flex: 1,
      },
    },
    loginButton: {
      minWidth: 168,
      [theme.breakpoints.down("xs")]: {
        minWidth: 165,
        flex: 1,
      },
    },
  }),
);

export default function DialogFbcOfferRedemptionNotAvailableNotLoggedIn({
  handler,
}: {
  handler: DialogHandler<void>;
}) {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <HandlerDialog
      onBackdropClick={() => {
        handler.close();
      }}
      handler={handler}
      maxWidth={"sm"}
      fullWidth={true}
      fullScreen={isMobile}>
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Grid container className={classes.section} spacing={2}>
              <Grid item xs={12}>
                <Typography style={{ textAlign: "center" }} variant={"h3"}>
                  {intl.get(`fbc_offer_redemption.account_necessary.title`)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  style={{ textAlign: "center", marginBottom: "15px" }}
                  variant={"body1"}>
                  {intl.get(`fbc_offer_redemption.account_necessary.message`)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color={"primary"}
          onClick={() => {
            AuthenticationHelper.redirectToSignup();
          }}
          style={{ marginBottom: "15px" }}>
          {intl.get("fbc_offer_redemption.account_necessary.action_register")}
        </Button>
        <Button
          color={"primary"}
          onClick={() => {
            AuthenticationHelper.redirectToLogin();
          }}
          style={{ marginBottom: "15px" }}>
          {intl.get("fbc_offer_redemption.account_necessary.action_login")}
        </Button>
        <Button
          style={{ marginRight: "15px", marginBottom: "15px" }}
          color="secondary"
          onClick={() => {
            handler.close();
          }}>
          {intl.get("fbc_offer_redemption.account_necessary.action_cancel")}
        </Button>
      </DialogActions>
    </HandlerDialog>
  );
}
