import {
  Button,
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import { DialogHandler } from "../../../components/dialog/useDialogHandler";
import HandlerDialog from "../../../components/dialog/handlerDialog";
import * as React from "react";
import { SectionLabel } from "../../../components";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import LoadingButton from "../../../components/LoadingButton";
import * as FamilyBonusCardActions from "../../../actions/family.bonus.cards";
import * as UserActions from "../../../actions/auth";
import { useDispatch } from "react-redux";
import useDialogReset from "../../../components/dialog/useDialogReset";
import intl from "react-intl-universal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      margin: 0,
      paddingTop: 0,
    },
    section: {
      paddingTop: "20px",
      paddingBottom: "20px",
      [theme.breakpoints.down("xs")]: {
        paddingTop: "0",
      },
    },
    textField: {
      margin: 0,
    },
  }),
);
export default function DialogAddCard({
  handler,
  onSuccessfullyAdded,
}: {
  handler: DialogHandler<any>;
  onSuccessfullyAdded: () => void;
}) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const dispatch = useDispatch();

  const [cardId, setCardId] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState<string>();

  useDialogReset(handler, () => {
    setCardId("");
    setLoading(false);
    setError(undefined);
  });

  async function addCard() {
    setLoading(true);
    dispatch<any>(
      FamilyBonusCardActions.addFamilyBonusCard({
        cardId: cardId.replaceAll(" ", ""),
        completion: () => {
          onSuccessfullyAdded();
          setLoading(false);
          handler.close();
        },
        error: (status) => {
          setError(status);
          setCardId("");
          setLoading(false);
        },
      }),
    );
  }
  return (
    <HandlerDialog
      handler={handler}
      maxWidth={"sm"}
      fullWidth={true}
      fullScreen={isMobile}>
      <DialogTitle>
        {intl.get(`my_family_bonus_cards.add_card.title`)}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {error && (
          <Typography variant="body2" color={"error"}>
            {intl.get(`my_family_bonus_cards.add_card.error.${error}`)}
          </Typography>
        )}
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Grid container className={classes.section} spacing={0}>
              <Grid item xs={12} container direction="column">
                <SectionLabel>
                  {intl.get(`my_family_bonus_cards.add_card.label_number`)}
                </SectionLabel>
                <TextField
                  disabled={loading}
                  className={classes.textField}
                  value={cardId}
                  onChange={(event) => {
                    setCardId(event.target.value);
                    setError(undefined);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          disabled={cardId.replaceAll(" ", "").length !== 6}
          loading={loading}
          onClick={() => {
            addCard();
          }}
          buttonLabel={intl.get(`my_family_bonus_cards.add_card.action_add`)}
        />
        <Button
          color="secondary"
          onClick={() => {
            handler.close();
          }}>
          {intl.get(`my_family_bonus_cards.add_card.action_cancel`)}
        </Button>
      </DialogActions>
    </HandlerDialog>
  );
}
