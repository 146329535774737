import {
  createStyles,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { createBrowserHistory } from "history";
import * as React from "react";
import { connect } from "react-redux";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Router,
  Switch,
} from "react-router";
import { bindActionCreators } from "redux";
import * as intl from "react-intl-universal";
// @ts-ignore
import locale2 from "locale2";
// @ts-ignore
import ScrollMemory from "react-router-scroll-memory";
import { LastLocationProvider } from "react-router-last-location";
import { Authentication, LoginMethod, UserLocationState } from "./model/model";
import Landing from "./pages/Landing";
import { RootState } from "./reducers";
import withRoot from "./withRoot";
import EventsPage from "./pages/user/EventsPage";
import SeoPagesOverviewPage from "./pages/SeoPagesOverviewPage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import SmallMobileFooter from "./components/SmallMobileFooter";
import EventDetailPage from "./pages/user/EventDetailPage";
import ProfilePage from "./pages/user/ProfilePage";
import httpInterceptors from "./interceptors";
import * as AuthActions from "./actions/auth";
// @ts-ignore
import Progress from "./components/Progress";
import { isMobile, isIPad13 } from "react-device-detect";
import { Helmet } from "react-helmet";
import { AuthenticationHelper } from "./utils/authenticationHelper";
import { getParamValue, supportsStorage } from "./utils/utils";
import axios from "axios";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";

import "@fontsource/manrope/200.css";
import "@fontsource/manrope/300.css";
import "@fontsource/manrope/400.css";
import "@fontsource/manrope/500.css";
import "@fontsource/manrope/600.css";
import "@fontsource/manrope/700.css";
import "@fontsource/manrope/800.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/material-icons";
import "@fontsource/material-icons-rounded";
import "@fontsource/material-icons-outlined";

import CategoryPage from "./pages/user/CategoryPage";
import SearchPage from "./pages/user/SearchPage";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import deLocale from "date-fns/locale/de-AT";
import MobileBottomNavigation from "./components/BottomNavigation";
import MobileSearchPage from "./pages/user/MobileSearchFormPage";
import SeoPage from "./pages/user/SeoPage";
import MobileSettingsPage from "./pages/user/MobileSettingsPage";
import SwiperCore, { Autoplay } from "swiper";
import { isBot } from "./hooks/useBotDetection";
import SitemapPage from "./pages/SitemapPage";
import LocationOnboardingDialog from "./dialog/LocationOnboardingDialog";
import ShowFamilyBonusCardPage from "./pages/user/ShowFamilyBonusCardPage";
import MyFamilyBonusCardsPage from "./pages/user/fbc/MyFamilyBonusCardsPage";

export const PAGE_SIZE = isMobile && !isIPad13 ? 10 : 30;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dateRangeStyle = require("react-date-range/dist/styles.css");
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dateRangeTheme = require("react-date-range/dist/theme/default.css");

const germanLocalizations = require("./translations/de.json");

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      zIndex: 1,
      minWidth: "320px",
      fontFamily: theme.fontFamily,
      color: theme.bodyTextColor,
      overscrollBehaviorY: "contain",
    },
    appFrame: {
      display: "flex",
      width: "100%",
      height: "100%",
      flexDirection: "column",
      flexWrap: "nowrap",
      justifyContent: "flex-start",
      alignItems: "stretch",
      alignContent: "stretch",
      minHeight: "100vh",
      overscrollBehaviorY: "contain",
    },
    content: {
      width: "100%",
      height: "100%",
      paddingBottom: `env(safe-area-inset-bottom)`,
      paddingLeft: `env(safe-area-inset-left)`,
      paddingRight: `env(safe-area-inset-right)`,
      overflow: "hidden",
      flexGrow: 1,
      overscrollBehaviorY: "contain",
      display: "flex",
      flexDirection: "column",
    },
    progressContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    bottomAppBar: {
      top: "auto",
      bottom: 0,
    },

    errorRoot: {
      width: "100vw",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyItems: "center",
      flexDirection: "column",
      marginTop: "auto",
      marginBottom: "auto",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    errorContainer: {
      display: "flex",
      alignItems: "center",
      justifyItems: "center",
      flexDirection: "column",
      marginTop: "auto",
      marginBottom: "auto",
      marginLeft: "auto",
      marginRight: "auto",
      paddingTop: 32,
      paddingBottom: 32,
      paddingLeft: 32,
      paddingRight: 32,
      maxWidth: 600,
    },
    errorIcon: {
      width: 64,
      height: 64,
      color: theme.palette.warning.main,
    },
  });

export interface Props
  extends RouteComponentProps<void>,
    WithStyles<typeof styles> {
  authentication: Authentication;
  locationState: UserLocationState;
  actions: typeof AuthActions;
}

export interface State {
  initDone: boolean;
  topOffset: number;
  validated: boolean;
  performingTokenExchange: boolean;
}

const browserHistory = createBrowserHistory();

// @ts-ignore
const PrivateRoute = ({ component: Component, authenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authenticated ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location },
            search: props.location.search,
          }}
        />
      )
    }
  />
);

const locales = {
  en: germanLocalizations,
  de: germanLocalizations,
};

const datePickerLocales = {
  en: enLocale,
  de: deLocale,
};

class App extends React.Component<Props, State> {
  state = {
    initDone: false,
    topOffset: 0,
    validated: false,
    performingTokenExchange: false,
  };

  static determineLocale() {
    let localeString: string = locale2;
    if (locales[localeString]) {
      return localeString;
    }
    if (localeString.indexOf("-") !== -1) {
      localeString = localeString.substring(0, localeString.indexOf("-"));
      if (locales[localeString]) {
        return localeString;
      }
      return "en";
    }
    return "en";
  }

  componentDidMount() {
    this.loadLocales();

    SwiperCore.use([Autoplay]);

    if (
      getParamValue("access_token") !== null &&
      getParamValue("iframe") !== null
    ) {
      axios.defaults.headers.common.Authorization = `Bearer ${getParamValue(
        "access_token",
      )}`;
    }
    httpInterceptors.setupAxiosInterceptors(
      () => {
        return this.props.locationState;
      },
      browserHistory,
      this.props.actions,
    );
  }

  isAuthenticated(): Boolean {
    let sessionValidated = true;
    try {
      sessionValidated = window.sessionStorage.getItem("validated") !== null;
    } catch (error) {}

    return sessionValidated && this.state.validated;
  }

  loadLocales() {
    if (!this.state.initDone) {
      intl
        .init({
          currentLocale: App.determineLocale(),
          locales,
        })
        .then(() => {
          // After loading CLDR locale data, start to render
          this.setState({ initDone: true });
        });
    }
  }

  isPerformTokenExchange = false;

  componentDidUpdate(): void {
    if (this.state.initDone) {
      if (!isBot()) {
        AuthenticationHelper.checkInitialParameters();
        if (AuthenticationHelper.activationResponseErrorToHandle()) {
          AuthenticationHelper.redirectToLoginAfterActivation();
        } else if (
          AuthenticationHelper.hasLegacyAccessTokenToMigrate() &&
          !AuthenticationHelper.hasAuthorizationCodeResponseToHandle() &&
          !AuthenticationHelper.hasAuthorizationCodeToExchange()
        ) {
          AuthenticationHelper.redirectToAutoAuthentication();
        } else if (
          AuthenticationHelper.hasAuthorizationCodeResponseToHandle()
        ) {
          AuthenticationHelper.handleAuthorizationCodeResponse();
        } else if (AuthenticationHelper.hasAuthorizationCodeToExchange()) {
          if (
            !this.isPerformTokenExchange &&
            !this.state.performingTokenExchange
          ) {
            this.isPerformTokenExchange = true;
            this.setState({ performingTokenExchange: true });
            AuthenticationHelper.exchangeAuthorizationCode(
              this.props.actions,
              (success: boolean) => {
                if (success) {
                  this.isPerformTokenExchange = false;
                  this.setState({ validated: true });
                } else {
                  this.isPerformTokenExchange = false;
                }
              },
            );
          }
        } else {
          let sessionNotValidated = true;
          try {
            sessionNotValidated =
              window.sessionStorage.getItem("validated") === null;
          } catch (error) {}
          if (this.props.authentication.user === null || sessionNotValidated) {
            if (
              getParamValue("access_token") !== null &&
              getParamValue("iframe") !== null &&
              (axios.defaults.headers.common.Authorization === null ||
                axios.defaults.headers.common.Authorization === undefined ||
                axios.defaults.headers.common.Authorization === "")
            ) {
              axios.defaults.headers.common.Authorization = `Bearer ${getParamValue(
                "access_token",
              )}`;
            }

            this.props.actions.fetchUser(() => {
              this.setState({ validated: true });
            });
          }
        }
      } else {
        if (
          axios.defaults.headers.common.Authorization === null ||
          axios.defaults.headers.common.Authorization === undefined ||
          axios.defaults.headers.common.Authorization === ""
        ) {
          this.props.authentication.user = {
            user_id: "",
            first_name: "",
            last_name: "",
            children: [],
            email: "bot@blue-cherries.com",
            login_method: LoginMethod.Guest,
            status: "",
            b2c_profile: { user_id: -1, completed: true },
            organization_unit: "",
            has_family_bonus_cards: false,
            family_bonus_card_count: 0,
          };
          this.props.authentication.authenticated = true;
          window.sessionStorage.setItem("validated", "true");
          axios.defaults.headers.common.Authorization = `Bearer crawler-bot`;
          this.setState({ validated: true });
        }
      }
    }
  }

  renderRoutes() {
    return (
      <>
        <div className={this.props.classes.content}>
          <div style={{ height: this.state.topOffset }}>&nbsp;</div>
          <Switch>
            <Route exact={true} path="/landing" component={Landing} />
            <PrivateRoute
              exact={true}
              path="/events"
              component={EventsPage}
              key="events"
              authenticated={this.isAuthenticated()}
            />
            {!isBot() && isMobile && (
              <PrivateRoute
                exact={true}
                path="/search"
                component={MobileSearchPage}
                key="mobile_search"
                authenticated={this.isAuthenticated()}
              />
            )}
            {!isBot() && isMobile && (
              <PrivateRoute
                exact={true}
                path="/settings"
                component={MobileSettingsPage}
                key="mobile_settings"
                authenticated={this.isAuthenticated()}
              />
            )}
            {!isBot() && (
              <PrivateRoute
                exact={true}
                path="/events/search"
                component={SearchPage}
                key="events_search"
                authenticated={this.isAuthenticated()}
              />
            )}
            <PrivateRoute
              exact={true}
              path="/more"
              component={SeoPagesOverviewPage}
              key="event_overview"
              authenticated={this.isAuthenticated()}
            />
            <PrivateRoute
              exact={true}
              path="/categories/:id"
              component={CategoryPage}
              authenticated={this.isAuthenticated()}
            />
            <PrivateRoute
              exact={true}
              path="/profile"
              component={ProfilePage}
              authenticated={this.isAuthenticated()}
            />
            x
            <PrivateRoute
              exact={true}
              path="/events/:company/:title"
              component={EventDetailPage}
              authenticated={this.isAuthenticated()}
            />
            <PrivateRoute
              exact={true}
              path="/show-family-bonus-card/:card_number"
              component={ShowFamilyBonusCardPage}
              authenticated={this.isAuthenticated()}
            />
            <PrivateRoute
              exact={true}
              path="/family-bonus-cards"
              component={MyFamilyBonusCardsPage}
              key="family-bonus-cards"
              authenticated={this.isAuthenticated()}
            />
            /* TODO: Allow old event URLs, but when called via this, determine
            the company slug and normalized_title from the server response and
            redirect to the correct URL on the client */
            <PrivateRoute
              exact={true}
              path="/events/:id"
              component={EventDetailPage}
              authenticated={this.isAuthenticated()}
            />
            <Route
              exact={true}
              path="/sitemap"
              component={SitemapPage}
              key="sitemap"
            />
            <Route exact={true} path="/" component={Landing} />
            <PrivateRoute
              exact={true}
              path="/:seo"
              component={SeoPage}
              key="seo"
              authenticated={this.isAuthenticated()}
            />
            <Redirect
              to={{
                pathname: "/",
                search:
                  this.props.location && this.props.location.search
                    ? this.props.location.search
                    : "",
              }}
            />
          </Switch>
        </div>
      </>
    );
  }

  renderProgress() {
    const { classes } = this.props;

    if (!this.state.initDone) {
      return (
        <div className={classes.progressContainer}>
          <Progress />
        </div>
      );
    }
  }

  renderStorageError() {
    const { classes } = this.props;

    if (this.state.initDone) {
      return (
        <Router history={browserHistory as any}>
          <div className={classes.root}>
            <div className={classes.appFrame}>
              <ScrollMemory />
              <Header />
              <div className={classes.errorContainer}>
                <EmojiObjectsIcon className={classes.errorIcon} />
                <Typography variant={"h3"} style={{ marginTop: 16 }}>
                  {intl.get("error_storage_title")}
                </Typography>
                <Typography
                  variant={"subtitle2"}
                  style={{ marginTop: 16, textAlign: "center" }}
                  dangerouslySetInnerHTML={{
                    __html: intl.get("error_storage_message"),
                  }}></Typography>
              </div>
              {!isMobile && !AuthenticationHelper.isRunningInMobileApp() && (
                <Footer className={classes.bottomAppBar} />
              )}
              {isMobile && !AuthenticationHelper.isRunningInMobileApp() && (
                <SmallMobileFooter className={classes.bottomAppBar} />
              )}
              {isMobile && (
                <MobileBottomNavigation
                  style={{
                    paddingTop: `env(safe-area-inset-bottom)`,
                    paddingBottom: `calc(env(safe-area-inset-bottom) * 2)`,
                    paddingLeft: `env(safe-area-inset-left)`,
                    paddingRight: `env(safe-area-inset-right)`,
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 99,
                  }}
                />
              )}
            </div>
          </div>
        </Router>
      );
    }
  }

  renderRouter() {
    const { classes } = this.props;

    if (this.state.initDone) {
      return (
        <Router history={browserHistory as any}>
          <LastLocationProvider>
            <div className={classes.root}>
              <div className={classes.appFrame}>
                <ScrollMemory />
                <Header />
                {this.isAuthenticated() && this.renderRoutes()}
                {!this.isAuthenticated() && <div style={{ flexGrow: 1 }} />}
                {!isMobile && !AuthenticationHelper.isRunningInMobileApp() && (
                  <Footer className={classes.bottomAppBar} />
                )}
                {isMobile && !AuthenticationHelper.isRunningInMobileApp() && (
                  <SmallMobileFooter className={classes.bottomAppBar} />
                )}
                {isMobile && (
                  <MobileBottomNavigation
                    style={{
                      paddingTop: `env(safe-area-inset-bottom)`,
                      paddingBottom: `calc(env(safe-area-inset-bottom) * 2)`,
                      paddingLeft: `env(safe-area-inset-left)`,
                      paddingRight: `env(safe-area-inset-right)`,
                      position: "fixed",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      zIndex: 99,
                    }}
                  />
                )}
              </div>
            </div>
            <LocationOnboardingDialog />
          </LastLocationProvider>
        </Router>
      );
    }
  }

  render() {
    return (
      this.state.initDone && (
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          locale={datePickerLocales[App.determineLocale()] || enLocale}>
          <>
            <Helmet>
              <title>
                {intl.get(`app.name.${process.env.REACT_APP_FLAVOR}`)}
              </title>
              <meta
                name="description"
                content={intl.get("app.default_description")}
                data-react-helmet="true"
              />
            </Helmet>

            {this.renderProgress()}
            {supportsStorage() === false && this.renderStorageError()}
            {supportsStorage() && this.renderRouter()}
          </>
        </MuiPickersUtilsProvider>
      )
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    authentication: state.authentication,
    locationState: state.userLocationState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AuthActions as any, dispatch),
  };
}

// @ts-ignore
export default withRoot(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(App)),
);
